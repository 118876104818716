import { ReactElement, ReactNode } from 'react';
import { Paper, useMediaQuery, Grid, GridProps } from '@mui/material';

type Props = {
  childrenBar: ReactNode;
  childrenMap: ReactNode;
};

export default function PageLayout(props: Props): ReactElement {
  const matches: boolean = useMediaQuery('(max-width:820px)');
  const styleBar: GridProps = matches
    ? {
        xs: 3,
        style: {
          height: 'calc(var(--vh, 1vh) * 100 - 72px - 2px)'      
        },
        item: true
      }
    : {
        style: {
          width: '370px',
          height: 'calc(var(--vh, 1vh) * 100 - 72px - 2px)'      
        }
      };

  const styleMap: GridProps = matches
    ? {
        xs: 9,
        style: {
          height: 'calc(var(--vh, 1vh) * 100 - 72px - 2px)'      
        },
        item: true
      }
    : {
        style: {
          width: 'calc(100vw - 370px)',
          height: 'calc(var(--vh, 1vh) * 100 - 72px - 2px)'      
        }
      };

  return (
    <Grid container>
      <Grid {...styleBar}>
        <Paper elevation={1}>{props.childrenBar}</Paper>
      </Grid>
      <Grid {...styleMap}>
        {/* <Paper elevation={1}> */}
        {props.childrenMap}
        {/* </Paper> */}
      </Grid>
    </Grid>
  );
}
