import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useTrips } from '../../contexts/trips';
import { format } from 'date-fns';

import { Vehicle, IPosition } from '../../data/vehicle';
import { Trip } from '../../data/trip';
import { Grid } from '@mui/material';
import * as rest from '../../lib/api/rest';

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { GoogleMap, Marker, Polyline } from '@react-google-maps/api';

import { mapOptions } from '../../assets/mapOptions';

export function TripsList() {
  const { t } = useTranslation();
  const [selectedTrip, setSelectedTrip] = useState<Trip>();
  const [selectedTripIndex, setSelectedTripIndex] = useState<
    number | undefined
  >(undefined);
  const { trips, nextCursor, isLoading, fetch, fetchMore, focus } = useTrips();
  const [vehicles, setVehicles] = useState<Array<Vehicle>>();

  useEffect(() => {
    (async () => {
      try {
        const { vehicles } = await rest.vehicle.list({ limit: 20 });
        setVehicles(vehicles);
      } catch (error) {}
    })();
    fetch();
  }, []);

  const onChange = (event: SelectChangeEvent) => {
    if (event.target.value) {
      fetch(event.target.value as string);
    } else {
      fetch();
    }
    setSelectedTrip(undefined);
    setSelectedTripIndex(undefined);
  };

  const onClickRow = (trip: Trip, index: number) => {
    focus(trip);
    setSelectedTrip(trip);
    setSelectedTripIndex(index);
  };

  const polylineOptions = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 7,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1
  };

  const body1Style = {
    fontWeight: 500,
    fontSize: '1.3rem',
    lineHeight: 1.6,
    letterSpacing: '0.0075em'
  };

  return (
    <>
      <Grid item xs={8}>
        {!vehicles ? (
          <LinearProgress color="secondary" />
        ) : (
          <Paper className={'history-list-paper'}>
            <Box className={'history-list-container'}>
              <Box className="history-filter-select">
                <FormControl fullWidth>
                  <InputLabel>{t('from.message.select.vehicle')}</InputLabel>
                  <Select
                    inputProps={{ className: 'history-filter-input' }}
                    MenuProps={{
                      PaperProps: { className: 'history-filter-paper' }
                    }}
                    defaultValue={''}
                    onChange={onChange}
                    label={t('from.message.select.vehicle')}
                  >
                    <MenuItem
                      className="history-dropdown-list"
                      value={''}
                      key={''}
                    >
                      {t('trip.nofilter')}
                    </MenuItem>
                    {vehicles?.map((vehicle) => (
                      <MenuItem
                        className="history-dropdown-list"
                        value={vehicle.id}
                        key={vehicle.id}
                      >
                        {`${vehicle.name} (${t('vehicle.id')}: ${vehicle.id})`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <TableContainer className={'history-list-table-container'}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className="history-table-header">
                        {t('trip.vehicle.id')}
                      </TableCell>
                      <TableCell className="history-table-header">
                        {t('trip.vehicle.name')}
                      </TableCell>
                      <TableCell className="history-table-header">
                        {t('trip.start.time')}
                      </TableCell>
                      <TableCell className="history-table-header">
                        {t('trip.start.station')}
                      </TableCell>
                      <TableCell className="history-table-header">
                        {t('trip.end.time')}
                      </TableCell>
                      <TableCell className="history-table-header">
                        {t('trip.end.station')}
                      </TableCell>
                      <TableCell className="history-table-header">
                        {t('trip.duration')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {trips?.map((trip, index) => (
                      <TableRow
                        selected={selectedTripIndex === index}
                        className="history-table-row"
                        onClick={() => onClickRow(trip, index)}
                      >
                        <TableCell className="history-table-cell">
                          {trip.vehicleID}
                        </TableCell>
                        <TableCell className="history-table-cell">
                          {trip.vehicleName}
                        </TableCell>
                        <TableCell className="history-table-cell">
                          {format(trip.start.startTime, 'yyyy/MM/dd HH:mm')}
                        </TableCell>
                        <TableCell className="history-table-cell">
                          {trip.start.stationName}
                        </TableCell>
                        <TableCell className="history-table-cell">
                          {trip.end
                            ? format(trip.end.endTime, 'yyyy/MM/dd HH:mm')
                            : t('trip.notReturned')}
                        </TableCell>
                        <TableCell className="history-table-cell">
                          {trip.end?.stationName || t('trip.notReturned')}
                        </TableCell>
                        <TableCell className="history-table-cell">
                          {!trip.end
                            ? t('trip.notReturned')
                            : !trip.duration
                            ? t('global.invalid')
                            : trip.duration.days && trip.duration.days >= 1
                            ? t('trip.duration.over24h')
                            : `${trip.duration.hours || 0}h ${
                                trip.duration.minutes
                                  ? trip.duration.minutes.toLocaleString(
                                      'en-US',
                                      {
                                        minimumIntegerDigits: 2,
                                        useGrouping: false
                                      }
                                    )
                                  : '00'
                              }m`}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {nextCursor && !isLoading && (
                  <Button
                    fullWidth
                    sx={{ paddingY: '0.5rem' }}
                    onClick={() => fetchMore()}
                  >
                    {t('button.loadMore')}
                  </Button>
                )}
              </TableContainer>
            </Box>
          </Paper>
        )}
      </Grid>
      {selectedTrip && Object.keys(selectedTrip).length > 0 && (
        <Grid xs={4}>
          <Paper
            elevation={1}
            sx={{ height: 'calc(var(--vh, 1vh) * 100 - 72px - 2px)', overflow: 'auto' }}
          >
            <Box sx={{ padding: '2rem 1.5rem' }}>
              <Box>
                <Typography variant="subtitle1">
                  {t('trip.vehicle.name')}
                </Typography>
                <Typography variant="body1" sx={{ ...body1Style, flexGrow: 1 }}>
                  {selectedTrip.vehicleName}
                </Typography>
              </Box>
              <Box justifyContent="space-between">
                <Typography variant="subtitle1">
                  {t('trip.duration')}
                </Typography>
                <Box display="flex" justify-content="space-between">
                  <Typography
                    variant="body1"
                    sx={{ ...body1Style, flexGrow: 1 }}
                  >
                    {!selectedTrip.end
                      ? t('trip.notReturned')
                      : !selectedTrip.duration
                      ? t('global.invalid')
                      : selectedTrip.duration.days &&
                        selectedTrip.duration.days >= 1
                      ? t('trip.duration.over24h')
                      : `${selectedTrip.duration.hours || 0}h ${
                          selectedTrip.duration.minutes
                            ? selectedTrip.duration.minutes.toLocaleString(
                                'en-US',
                                {
                                  minimumIntegerDigits: 2,
                                  useGrouping: false
                                }
                              )
                            : '00'
                        }m`}
                  </Typography>
                  <Box sx={{ textAlign: 'end' }}>
                    <Typography variant="subtitle2">
                      {`${t('trip.start.time')}: ${format(
                        selectedTrip.start.startTime,
                        'yyyy/MM/dd HH:mm'
                      )}`}
                    </Typography>
                    <Typography variant="subtitle2">
                      {`${t('trip.end.time')}: ${
                        selectedTrip.end
                          ? format(selectedTrip.end.endTime, 'yyyy/MM/dd HH:mm')
                          : t('trip.notReturned')
                      }`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {selectedTrip?.path.length > 1 ? (
                <Box
                  sx={{
                    border: '1px solid',
                    borderColor: '#d7d7d7',
                    borderRadius: '5px',
                    height: '35vh',
                    marginY: '1rem',
                    width: '100%'
                  }}
                >
                  <GoogleMap
                    zoom={17}
                    mapContainerStyle={{ width: '100%', height: 'inherit' }}
                    center={selectedTrip.path[0]}
                    clickableIcons={false}
                    options={mapOptions}
                  >
                    {selectedTrip.path.length > 1 &&
                      selectedTrip.path.map(
                        (position: IPosition, i: number) => {
                          if (i === 0) {
                            return (
                              <Marker
                                position={position}
                                label={{
                                  color: '#ffffff',
                                  text: '\ue037',
                                  fontFamily: 'Material Icons',
                                  fontSize: '1.1rem'
                                }}
                                icon={{
                                  fillColor: '#33ae00',
                                  fillOpacity: 2,
                                  path: google.maps.SymbolPath.CIRCLE,
                                  scale: 12,
                                  strokeColor: '#000066',
                                  strokeWeight: 1
                                }}
                              ></Marker>
                            );
                          }
                          if (
                            selectedTrip.end &&
                            selectedTrip.path.length === i + 1
                          ) {
                            return (
                              <Marker
                                position={position}
                                label={{
                                  color: '#ffffff',
                                  text: '\ue047',
                                  fontFamily: 'Material Icons',
                                  fontSize: '1.1rem'
                                }}
                                icon={{
                                  fillColor: 'red',
                                  fillOpacity: 2,
                                  path: google.maps.SymbolPath.CIRCLE,
                                  scale: 12,
                                  strokeColor: '#000066',
                                  strokeWeight: 1
                                }}
                              ></Marker>
                            );
                          }
                          return;
                        }
                      )}
                    <Polyline
                      path={selectedTrip.path}
                      options={polylineOptions}
                    />
                  </GoogleMap>
                </Box>
              ) : (
                <Box
                  sx={{
                    background: '#e5e3df',
                    border: '1px solid',
                    borderColor: '#d7d7d7',
                    borderRadius: '5px',
                    height: '35vh',
                    marginY: '1rem',
                    width: '100%',
                    position: 'relative',
                    textAlign: 'center'
                  }}
                >
                  <Box
                    sx={{
                      transform: 'translate(-50%, -50%)',
                      top: '50%',
                      left: '50%',
                      position: 'absolute',
                      width: 'inherit'
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ fontSize: '1.5rem', padding: '1rem' }}
                    >
                      {t('trip.path.message.unavailable')}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Paper>
        </Grid>
      )}
    </>
  );
}
